import type {
	AudioTrackInterface,
	DashDrmInterface,
	DrmType,
	ErrorCode,
	PlaybackAdapterContextInterface,
	PlaybackAdapterInterface,
	PlaybackMetricsInterface,
	QualityInterface,
	RangeInterface,
	ResourcePlaybackAbrInterface,
	TextTrackInterface,
	ThumbnailDataInterface,
} from '@cbsinteractive/avia-js';
import { ShakaAdapterOptionsInterface } from './iface/ShakaAdapterOptionsInterface';
import { ShakaRobustness } from './ShakaRobustness';

export const SHAKA = 'shaka';

export function isShakaSupported(context: PlaybackAdapterContextInterface) {
	const { avia } = context;
	const { MimeType, PlaybackAdapterBase } = avia;

	return PlaybackAdapterBase.canPlay(context, [MimeType.DASH]);
}

export async function createShakaAdapter(context: PlaybackAdapterContextInterface, options?: ShakaAdapterOptionsInterface): Promise<PlaybackAdapterInterface> {
	const { avia, resource, system, textTrackSettings, playerOptions } = context;
	const { playback } = resource;
	const { AudioTrackType, DrmType, ErrorCode, PlaybackAdapterBase, PlayerError, Util } = avia;

	if (!system.global.shaka) {
		try {
			const debug = options.debug ? '.debug' : '';
			await Util.loadScript(
				{ url: options.dependencies?.shaka || `//cdnjs.cloudflare.com/ajax/libs/shaka-player/4.10.14/shaka-player.compiled${debug}.js` },
				playerOptions,
				() => {
					return system.global.shaka;
				},
			);
		}
		catch (error) {
			throw new PlayerError(ErrorCode.SHAKA_SDK_MISSING, 'Could not load Shaka Player SDK', error);
		}
	}

	const {
		ErrorMessage,
		Header,
		PlayerHookType,
		PerformanceMode,
		RequestObjectType,
		TextTrackKind,
	} = avia;
	const shaka: any = system.global.shaka;
	const isActive = (track: any) => track.active;
	const equal = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);
	const isUnd = (track: AudioTrackInterface) => track?.language === 'und';

	/**
	 * Shaka Adapter
	 */
	class ShakaAdapter extends PlaybackAdapterBase {

		player: any;

		private cleanUpVtt = false;
		private playerConfig: any;
		private isLiveStream: boolean = false;
		private renderTextTrackNatively: boolean = true;
		private domTextTrack: TextTrack;
		private audioSwitching: boolean = false;
		private textTimeout: any;
		private manifestVariants: any[];
		private playerEventMap: Record<string, any> = {
			error: (event: any) => this.onError(event),
			streaming: () => this.onStreaming(),
			variantchanged: () => this.onVariantChanged(),
			adaptation: () => this.onVariantChanged(),
			manifestupdated: (event: any) => this.onManifestUpdated(event),
			emsg: (event: any) => this.onEmsg(event),
			metadata: (event: any) => this.onMetadata(event),
			trackschanged: Util.debounce(() => this.onTracksChanged(), 25),
			drmsessionupdate: () => this.onDrmSessionUpdate(),
			timelineregionenter: (event: any) => this.onTimelineRegionEnter(event),
		};

		/**
		 * ShakaAdapter
		 */
		constructor(context: PlaybackAdapterContextInterface, options: ShakaAdapterOptionsInterface = {}) {
			super(context, options, false);

			if (options.debounceTracksChanged === false) {
				this.playerEventMap.trackschanged = () => this.onTracksChanged();
			}

			this.logger.info(`Shaka Player version: ${shaka.Player.version}`);

			// Shaka polyfill for VTTCue can cause issues when hlsjs is loaded after Shaka. See VTG-2189
			if (typeof VTTCue === 'undefined') {
				this.cleanUpVtt = true;
			}

			// player initialization
			shaka.polyfill.installAll();

			this.player = new shaka.Player();
			this.player.attach(this.video);
			const playerConfig = this.playerConfig = this.player.getConfiguration();

			// listeners
			this.addEvents(this.player, this.playerEventMap);

			// retry config
			let retry = this.playerConfig.streaming.retryParameters;
			retry.maxAttempts = ShakaRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS;
			retry.baseDelay = ShakaRobustness.FATAL_ERROR_RECOVERY_DELAY;
			retry.backoffFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_BACKOFF;
			retry.fuzzFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_FUZZ;

			retry = this.playerConfig.manifest.retryParameters;
			retry.maxAttempts = ShakaRobustness.MANIFEST_RETRY_ATTEMPTS;
			retry.baseDelay = ShakaRobustness.MANIFEST_RETRY_INTERVAL;

			// network config
			const networkEngine = this.player.getNetworkingEngine();
			networkEngine.registerRequestFilter(this.onRequest);
			networkEngine.registerResponseFilter(this.onResponse);

			// drm config
			const { playready, widevine } = resource.location.drm;
			const { PLAYREADY, WIDEVINE } = DrmType;
			const configureDrm = (drm: DashDrmInterface, dfltSystem: DrmType) => {
				const { keySystem: system = dfltSystem } = drm;
				if (drm?.url) {
					playerConfig.drm.servers[system] = drm.url;
					playerConfig.drm.advanced[system] = {};

					if (drm.audioRobustness) {
						playerConfig.drm.advanced[system].audioRobustness = drm.audioRobustness;
					}

					if (drm.videoRobustness) {
						playerConfig.drm.advanced[system].videoRobustness = drm.videoRobustness;
					}

					playerConfig.drm.preferredKeySystems.push([system, drm.priority]);
				}
			};

			configureDrm(widevine, WIDEVINE);
			configureDrm(playready, PLAYREADY);

			playerConfig.drm.preferredKeySystems = this.playerConfig.drm.preferredKeySystems
				.sort((a: any[], b: any[]) => a[1] - b[1])
				.map((i: any[]) => i[0]);

			// abr config
			const abrConfig: ResourcePlaybackAbrInterface = playback.abr;
			const { restrictions, abr } = this.playerConfig;

			if (!isNaN(abrConfig.minBitrate)) {
				this.minBitrate = abrConfig.minBitrate;
				restrictions.minBandwidth = abrConfig.minBitrate;
			}

			if (!isNaN(abrConfig.maxBitrate)) {
				this.maxBitrate = abrConfig.maxBitrate;
				restrictions.maxBandwidth = abrConfig.maxBitrate;
			}

			if (!isNaN(abrConfig.startBitrate)) {
				abr.defaultBandwidthEstimate = abrConfig.startBitrate * 1.15;
			}

			// performance settings
			const settings = context.performanceSettings;
			if (settings.forwardBufferLength != null) {
				playerConfig.streaming.bufferingGoal = settings.forwardBufferLength;
			}

			if (settings.backBufferLength != null) {
				playerConfig.streaming.bufferBehind = settings.backBufferLength;
			}

			if (settings.mode === PerformanceMode.LOW) {
				// this.playerConfig.streaming.gapDetectionThreshold = 2.5;
				// this.playerConfig.streaming.smallGapLimit = 2.5;
			}

			this.renderTextTrackNatively = textTrackSettings.native;

			// advanced video and audio codec settings
			const enableAdvancedCodecs = playback.enableAdvancedCodecs;

			// Default to 2 audio channels unless advanced codecs are set.
			if (playerConfig.restrictions.maxChannelsCount) {
				playerConfig.restrictions.maxChannelsCount = enableAdvancedCodecs ? Infinity : 2;
			}
			// Default to avc video and mp4 audio codecs; enableAdvancedCodecs will override if set.
			playerConfig.preferredVideoCodecs = ['avc'];
			playerConfig.preferredAudioCodecs = ['mp4'];

			// TODO  - accept an empty array?
			const userVideoCodecs = !Util.isEmpty(playback.preferredVideoCodecs) ? playback.preferredVideoCodecs : null;
			const userAudioCodecs = !Util.isEmpty(playback.preferredAudioCodecs) ? playback.preferredAudioCodecs : null;

			if (enableAdvancedCodecs) {
				playerConfig.preferredVideoCodecs = userVideoCodecs || ['hev1.2', 'hvc1.2', 'dvhe'];
				playerConfig.preferredAudioCodecs = userAudioCodecs || ['ec-3', 'ac-3'];
			}

			playerConfig.preferredTextLanguage = textTrackSettings.language;
			playerConfig.preferredAudioLanguage = playerOptions.audioLanguage;

			// makes seek window equal to the duration allowing seeking behavior for LTS
			// applies to hls playback only
			playerConfig.manifest.hls.useSafariBehaviorForLive = false;

			// disable dash xlink processing
			playerConfig.manifest.dash.disableXlinkProcessing = true;

			// CMCD
			const { cmcd } = resource;
			if (cmcd.enabled) {
				playerConfig.cmcd = {
					enabled: true,
					contentId: cmcd.contentId || '',
					sessionId: cmcd.sessionId || '',
					useHeaders: cmcd.useHeaders,
				};
			}

			if (options.flattenCues === true) {
				playerConfig.textDisplayFactory = () => {
					const textDisplayer = new shaka.text.SimpleTextDisplayer(this.video);
					const append = textDisplayer.append.bind(textDisplayer);

					const flatten = (cues: any[]) => cues.reduce((acc: any[], cue: any) => {
						if (cue.payload) {
							acc.push(Object.assign({}, cue, { nestedCues: [] }));
						}

						if (cue.nestedCues.length) {
							acc = acc.concat(flatten(cue.nestedCues));
						}

						return acc;
					}, []);

					textDisplayer.append = (cues: any) => append(flatten(cues));

					return textDisplayer;
				};
			}

			// Merge all overrides
			Util.merge(
				playerConfig,
				options.config,
			);

			this.configure();

			// request filter for aes-128
			const aes = context.resource.location.drm.aes;
			const needsAes = !Util.isEmpty(aes) && aes.header && aes.provider;
			if (needsAes) {
				this.player.getNetworkingEngine().registerRequestFilter((type: number, request: any) => {
					const { RequestType } = shaka.net.NetworkingEngine;
					const url = request.uris[0];

					if (type === RequestType.KEY) {
						url.includes(aes.provider) && Object.assign(request.headers, aes.header);
					}
				});
			}

			// register for request hooks
			this.player.getNetworkingEngine().registerRequestFilter((type: number, request: any) => {
				if (!this.delegate.hasHook(PlayerHookType.REQUEST)) {
					return undefined;
				}

				const value = Util.createRequest(request.uris[0], request.headers);
				const { RequestType } = shaka.net.NetworkingEngine;

				let objectType;

				switch (type) {
					case RequestType.MANIFEST:
						objectType = RequestObjectType.MANIFEST;
						break;

					case RequestType.SEGMENT:
						objectType = RequestObjectType.SEGMENT;
						break;

					case RequestType.LICENSE:
					case RequestType.SERVER_CERTIFICATE:
					case RequestType.KEY:
						objectType = RequestObjectType.KEY;
						break;

					default:
						objectType = RequestObjectType.OTHER;
				}

				const metadata = { objectType };

				return this.delegate.applyHook(PlayerHookType.REQUEST, value, metadata)
					.then(value => {
						request.uris[0] = value.url;
						request.headers = value.headers;
						request.allowCrossSiteCredentials = value.credentials === 'include';
					});
			});

			// register for request hooks
			this.player.getNetworkingEngine().registerResponseFilter((type: number, response: any) => {
				if (!this.delegate.hasHook(PlayerHookType.RESPONSE)) {
					return undefined;
				}

				const isManifest = type === shaka.net.NetworkingEngine.RequestType.MANIFEST;
				let { data } = response;

				if (isManifest && typeof TextDecoder !== 'undefined') {
					data = (new TextDecoder()).decode(data);
				}

				return this.delegate.applyHook(PlayerHookType.RESPONSE, { url: response.uri, data })
					.then(value => {
						response.uri = value.url;
						response.data = (isManifest && typeof TextEncoder !== 'undefined') ? (new TextEncoder()).encode(value.data) : value.data;
					});
			});
		}

		override destroy(): Promise<void> {
			this.playerEventMap.trackschanged.cancel?.();

			this.removeEvents(this.player, this.playerEventMap);

			clearTimeout(this.textTimeout);

			return this.player.destroy()
				.then(() => {
					this.player = null;
					this.playerConfig = null;
					this.domTextTrack = null;

					if (this.cleanUpVtt) {
						delete window.VTTCue;
					}

					return super.destroy();
				});
		}

		/**
		 *
		 */
		override getId() {
			return SHAKA;
		}

		/**
		 *
		 */
		override load() {
			return super.load()
				.then(async metadata => {
					const startTime = playback.startTime;

					try {
						await this.player.load(resource.location.mediaUrl, Util.isValidPlayheadTime(startTime) ? startTime : undefined);
						this.isLiveStream = this.player.isLive();
						this.manifestVariants = this.player.getVariantTracks();

						const textTrackUrl = resource.location.textTrackUrl;
						if (!Util.isEmpty(textTrackUrl)) {
							const mime = Util.getMimeType(textTrackUrl);
							await this.player.addTextTrackAsync(textTrackUrl, 'en', TextTrackKind.CAPTIONS, mime);
						}

						this.suppressErrors = false;

						metadata.fragment.mimeType = this.getActiveVariantTrack()?.mimeType || '';
						return metadata;
					}
					catch (error) {
						// Shaka does not dispatch an error event when a manifest parse error occurs. Force one here:
						throw this.createPlayerError(error);
					}
				});
		}

		/**
		 *
		 */
		override resize(): void {
			const { maxHeight } = playback.abr;
			const { restrictions } = this.playerConfig;

			if (!this.qualityCappedToScreenSize && !Number.isFinite(maxHeight)) {
				restrictions.maxWidth = undefined;
				restrictions.maxHeight = undefined;
				this.configure();

				return;
			}

			const low = this.getVariantTracks()[0];
			if (!low) {
				return;
			}

			let { clientWidth, clientHeight } = this.video;

			if (clientWidth < low.width || clientHeight < low.height) {
				clientWidth = low.width;
				clientHeight = low.height;
			}

			if (restrictions.maxWidth === clientWidth && restrictions.maxHeight === clientHeight) {
				return;
			}

			restrictions.maxWidth = clientWidth;
			restrictions.maxHeight = Math.min(clientHeight, maxHeight);

			this.configure();
		}

		/**
		 *
		 */
		override suspend(): void {
			this.playerConfig.streaming.bufferingGoal = 1;
			this.playerConfig.streaming.rebufferingGoal = 1;
			this.configure();
		}

		/**
		 *
		 */
		override resume(): void {
			this.playerConfig.streaming.bufferingGoal = 10;
			this.playerConfig.streaming.rebufferingGoal = 10;
			this.configure();
		}

		/**
		 *
		 */
		override getIsLiveStream() {
			return this.isLiveStream;
		}

		/**
		 *
		 */
		override clearText(): void {
			Util.clearCue(this.domTextTrack, this.video.currentTime);
		}

		/**
		 *
		 */
		override setMinBitrate(value: number): void {
			super.setMinBitrate(value);

			if (isNaN(value)) {
				value = -Infinity;
			}
			this.playerConfig.restrictions.minBandwidth = value;
			this.configure();
		}

		/**
		 *
		 */
		override setMaxBitrate(value: number): void {
			super.setMaxBitrate(value);

			if (isNaN(value)) {
				value = Infinity;
			}

			const maxAudioBandwidth = this.manifestVariants.reduce((acc, variant) => Math.max(variant.audioBandwidth, acc), 0);

			this.playerConfig.restrictions.maxBandwidth = value + maxAudioBandwidth;
			this.configure();
		}

		/**
		 *
		 */
		override setAutoQualitySwitching(value: boolean): void {
			this.playerConfig.abr.enabled = value;
			this.configure();
		}

		/**
		 *
		 */
		override setQuality(quality: QualityInterface): void {
			const track = this.player.getVariantTracks().find((track: any) => track.id === quality.index);
			if (!track) {
				this.logger.warn('Quality track not found, will not set.');
				return;
			}
			this.player.selectVariantTrack(track, true);
		}

		/**
		 *
		 */
		override setAudioTrack(track: AudioTrackInterface) {
			if (!track) {
				this.logger.info('Audio track is null, will not set.');

				return;
			}

			this.audioSwitching = true;
			this.player.selectAudioLanguage(track.language, track.type);
		}

		/**
		 *
		 */
		override setTextTrack(value: TextTrackInterface): void {
			if (!value || value.id === this.textTrack?.id) {
				return;
			}

			this.textTrack = value;
			this.delegate.textTrackChange(value);

			this.updateTextTrack();
		}

		/**
		 *
		 */
		override setTextTrackMode(mode: TextTrackMode): void {
			this.updateTextTrack();
			this.delegate.textTrackDisplayModeChange(mode);
		}

		/**
		 *
		 */
		override getMetrics(): PlaybackMetricsInterface {
			const variant = this.getActiveVariantTrack();
			const stats = this.player.getStats();

			return {
				droppedVideoFrames: stats.droppedFrames,
				framerate: variant.frameRate || Number.NaN,
				bandwidth: stats.estimatedBandwidth,
			};
		}

		/**
		 *
		 */
		override getThumbnails(time: number): Promise<ThumbnailDataInterface[]> {
			return super.getThumbnails(time)
				.then(thumbnails => {
					if (this.getIsLiveStream()) {
						const { start, end } = this.player.seekRange();
						time = Util.mapToRange(time, 0, end - start, start, end);
					}
					return Promise.all(this.player.getImageTracks().map((track: any) => this.player.getThumbnails(track.id, time)))
						.then((thumbs: any[]) => {
							thumbs
								.filter(thumb => !!thumb)
								.forEach(thumb => {
									const { width, height, positionX, positionY, uris } = thumb;
									const url = uris[0];
									thumbnails.push({
										x: positionX,
										y: positionY,
										width,
										height,
										url,
									});
								});

							return thumbnails;
						});
				});
		}

		/**
		 *
		 */
		protected override getSeekable(): RangeInterface {
			return this.player.seekRange();
		}

		/**
		 *
		 */
		protected override getLiveStreamUtcStart() {
			const relativeMs = (this.getCurrentTime() - this.player.seekRange().start) * 1000;
			const utc = this.getLiveStreamUtcTime();
			return Math.round(utc - relativeMs);
		}

		/**
		 *
		 */
		protected override getLiveStreamUtcTime() {
			return this.player.getPlayheadTimeAsDate().getTime();
		}

		/**
		 *
		 */
		private configure(): void {
			this.player.configure(this.playerConfig);
		}

		/**
		 *
		 */
		private getVariantTracks(): any[] {
			return this.player.getVariantTracks().sort((a: any, b: any): number => a.bandwidth - b.bandwidth);
		}

		/**
		 *
		 */
		private getActiveVariantTrack(): any {
			return this.player.getVariantTracks().find(isActive) || {};
		}

		/**
		 *
		 */
		private getAudioTrackIndex(): number {
			const { language, audioRoles } = this.getActiveVariantTrack();
			const role = this.getAudioRoles(audioRoles);
			const audioTracks = this.player.getAudioLanguagesAndRoles();
			return audioTracks.findIndex((item: any) => item.language === language && item.role === role);
		}

		/**
		 *
		 */
		private getAudioTrack(): { language: string, role: string; } {
			return this.player.getAudioLanguagesAndRoles()[this.getAudioTrackIndex()];
		}

		/**
		 *
		 */
		private getAudioRoles(audioRoles: any): string {
			return audioRoles?.join(',') || '';
		}

		/**
		 *
		 */
		private updateAudioTracks(): void {
			const audioTracks = this.player.getAudioLanguagesAndRoles();
			const variants = this.player.getVariantTracks();
			const findVariant = (track: any) => variants.find((variant: any) =>
				variant.language === track.language && track.role === this.getAudioRoles(variant.audioRoles),
			);

			let tracks = audioTracks
				.map((track: any, index: number) => ({
					index,
					id: index.toString(),
					type: (track.role === 'description' || track.role === 'alternate') ? AudioTrackType.DESCRIPTION : AudioTrackType.MAIN,
					codec: findVariant(track)?.audioCodec || '',
					language: track.language,
					label: track.label || track.language,
					default: track.primary,
				}));

			let track = tracks[this.getAudioTrackIndex()];

			if (isUnd(track)) {
				const idx = tracks.length > track.index + 1 ? track.index + 1 : track.index;
				track = tracks[idx];
			}

			// Filter tracks labeled 'und' that are sometimes inserted during ad content
			tracks = tracks.filter((track: any) => !isUnd(track));
			if (!equal(tracks, this.audioTracks)) {
				this.audioTracks = tracks;
				this.delegate.audioTracksChange(this.audioTracks);
			}

			if (!equal(track, this.audioTrack)) {
				this.updateAudioTrack(track);
			}
		}

		/**
		 *
		 */
		private updateAudioTrack(track: AudioTrackInterface) {
			this.audioTrack = track;
			this.delegate.audioTrackChange(track);
			this.updateTextTrack();
		}

		/**
		 *
		 */
		private updateTextTrack() {
			const id = parseInt(this.textTrack?.id);
			const tracks = this.player.getTextTracks();
			const track = tracks.find((track: any) => track.id === id);
			const forced = tracks.find((track: any) => track.forced && track.language === this.audioTrack?.language);
			const selected = textTrackSettings.enabled ? track : forced;
			const visible = textTrackSettings.enabled ? !!track : !!forced;

			if (selected) {
				this.player.selectTextTrack(selected);
			}
			this.player.setTextTrackVisibility(visible);

			// NOTE: The textTrack mode needs to be overridden because Shaka uses showing/disabled,
			//       by default. When the renderTextTrackNatively is false, when need to force 'hidden'
			//       so the text track cue events are dispatched, but not rendered. Shaka's internal
			//       logic handles forced vs normal cues, so there is no need for disabled, simply
			//       force 'hidden' for all use cases.
			if (!this.renderTextTrackNatively) {
				this.textTimeout = setTimeout(() => this.domTextTrack.mode = 'hidden', 0);
			}
		}

		/**
		 *
		 */
		private updateTextTracks(): void {
			const textTracks = this.player.getTextTracks().filter((track: any) => !track.forced);
			const tracks = textTracks.map((track: any) => {
				const kind = /subtitle/.test(track.kind) ? TextTrackKind.SUBTITLES : track.kind;
				return {
					id: track.id.toString(),
					language: track.language,
					kind,
					label: track.label || track.language,
				};
			});

			if (!equal(tracks, this.textTracks)) {
				this.textTracks = tracks;
				this.delegate.textTracksChange(this.textTracks);
			}

			if (!this.domTextTrack) {
				this.domTextTrack = Array.from(this.video.textTracks).find((textTrack: TextTrack) => textTrack.label === 'Shaka Player TextTrack');
				this.listenToTextTracks(true);
			}

			const textTrack = textTracks.find(isActive);
			const id = textTrack?.id.toString();
			if (!this.textTrack || this.textTrack.id !== id) {
				let track = this.textTracks.find(track => track.id === id);
				if (!track) {
					track = Util.findDefaultTrack(this.textTracks, textTrackSettings.language);
				}

				this.setTextTrack(track);
				return;
			}

			// re-apply the text track visibility to workaround issues where
			// visibility was enabled during a period with no text tracks.
			this.player.setTextTrackVisibility(textTrackSettings.enabled);
		}

		private updateQuality() {
			const variant = this.getActiveVariantTrack();
			const id = variant?.id;
			const quality = this.qualities.find(quality => quality.index === id);

			// Occasionally shaka plays a variant that is no longer in the truncated list
			// if (!quality) {
			// 	quality = this.normalizeQuality(variant);
			// }

			this.quality = quality;
			this.delegate.qualityChange(quality);
		}

		private normalizeQuality(track: any): QualityInterface {
			return {
				index: track.id,
				bitrate: track.videoBandwidth != null ? track.videoBandwidth : track.bandwidth,
				width: track.width,
				height: track.height,
				codec: track.codecs,
				enabled: true,
				category: [],
			};
		}

		/**
		 *
		 */
		private createQualities(): QualityInterface[] {
			if (!this.manifestVariants) {
				return [];
			}

			const audio = this.getAudioTrack();
			return this.manifestVariants
				.filter((track: any) =>
					this.getAudioRoles(track.audioRoles) === audio.role &&
					Util.compareLanguageTags(track.language, audio.language),
				)
				.map(this.normalizeQuality);
		}

		/**
		 *
		 */
		private refreshQualities() {
			this.qualities = this.createQualities();
			this.updateQualities();

			if (isFinite(this.maxBitrate)) {
				this.setMaxBitrate(this.maxBitrate);
			}
		}

		/**
		 *
		 */
		private createPlayerError(error: any) {
			const Error = shaka.util.Error;
			const Category = Error.Category;
			const Code = Error.Code;

			const toError = (code: ErrorCode, message: string, cause: any, fatal: boolean = true) => {
				// Shaka Player errors do not have a string based message, so find the key associated with numeric error code.
				for (const key in Code) {
					const value = Code[key];
					if (value === error.code) {
						message += ` : ${key} / ${value}`;
						break;
					}
				}

				return this.createError(code, message, cause, fatal);
			};

			switch (error.category) {
				case Category.NETWORK:
					return toError(ErrorCode.SHAKA_NETWORK_ERROR, ErrorMessage.FATAL_PLAYBACK_NETWORK_ERROR, error);

				case Category.MANIFEST:
					return toError(ErrorCode.SHAKA_PARSE_ERROR, ErrorMessage.FATAL_PLAYBACK_MEDIA_ERROR, error);

				case Category.MEDIA:
					const code = (error.code === Code.VIDEO_ERROR && error.data[0] === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) ? ErrorCode.SHAKA_SRC_NOT_SUPPORTED : ErrorCode.SHAKA_MEDIA_ERROR;
					return toError(code, ErrorMessage.FATAL_PLAYBACK_MEDIA_ERROR, error);

				case Category.DRM:
					return toError(ErrorCode.SHAKA_DRM_ERROR, ErrorMessage.FATAL_PLAYBACK_MEDIA_ERROR, error);

				default:
					return toError(ErrorCode.UNSPECIFIED_SHAKA_ERROR, ErrorMessage.UNSPECIFIED_ERROR, error, false);
			}
		}

		/**
		 *
		 */
		private onRequest = (type: number, request: any): void => {
			const drm = this.context.resource.location.drm;

			if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
				if (drm.widevine?.header) {
					Object.assign(request.headers, drm.widevine.header);
				}

				if (drm.playready?.header) {
					Object.assign(request.headers, drm.playready.header);
				}
			}
		};

		/**
		 *
		 */
		private onResponse = (type: number, response: any): void => {
			const cdn = response.headers[Header.MULTI_CDN];
			if (cdn != null) {
				this.delegate.cdnChange(cdn);
			}
		};

		protected override onCueChange(event: Event): void {
			const track = this.player.getTextTracks().find((track: any) => track.active);
			if (!track) {
				return;
			}

			super.onCueChange(event, track.forced);
		}

		/**
		 *
		 */
		private onError(e: any): void {
			const { code, message, cause, fatal } = this.createPlayerError(e.detail);
			this.throwError(code, message, cause, fatal);
		}

		/**
		 *
		 */
		private onStreaming(): void {
			this.segmentDuration = this.player.getStats().maxSegmentDuration;
		}

		private async onManifestUpdated(event: any) {
			const mimeType = Util.getResourceMimeType(resource);
			const isHls = mimeType === avia.MimeType.HLS || mimeType === avia.MimeType.HLS_ALT;
			if (event.isLive || isHls) {
				return;
			}

			const { relativeTime, relativeDuration } = this.liveStreamInfo;
			playback.startTime = relativeTime / relativeDuration * this.getSeekable().end;
			this.isLiveStream = false;
			await this.load();
			this.video.play();
		}

		/**
		 *
		 */
		private onVariantChanged() {
			if (this.qualities == null) {
				return;
			}

			this.refreshQualities();
			this.updateQuality();

			if (this.audioSwitching) {
				this.audioSwitching = false;
				const { language, role } = this.getAudioTrack();
				const type = role === 'description' || role === 'alternate' ? AudioTrackType.DESCRIPTION : AudioTrackType.MAIN;
				const audioTrack = this.audioTracks.find(track => track.language === language && track.type === type);
				this.updateAudioTrack(audioTrack);
			}
		}

		/**
		 *
		 */
		private onTracksChanged(): void {
			if (!this.manifestVariants) {
				return;
			}

			this.resize();

			this.updateAudioTracks();
			this.updateTextTracks();

			this.refreshQualities();

			if (!this.quality) {
				this.updateQuality();
			}
		}

		/**
		 *
		 */
		private onDrmSessionUpdate(): void {
			this.delegate.drmKeySystemCreated(this.player.keySystem());
		}

		/**
		 *
		 */
		private onEmsg(event: any): void {
			const emsg: any = event.detail;

			if (emsg.schemeIdUri === 'https://aomedia.org/emsg/ID3') {
				return;
			}

			const txt = emsg.value;

			this.delegate.metadataCuepoint({
				id: emsg.schemeIdUri,
				info: txt,
				text: txt,
				data: emsg.messageData,
				source: emsg,
				startTime: emsg.startTime,
				endTime: emsg.endTime,
			});
		}

		/**
		 *
		 */
		private onMetadata(event: any): void {
			const { payload } = event;

			this.metadataSurface.addId3Metadata(payload.key, {
				id: '',
				info: payload.description,
				text: payload.data,
				data: payload.data,
				source: event,
				startTime: event.startTime,
				endTime: event.endTime,
			});
		}

		/**
		 *
		 */
		private onTimelineRegionEnter(event: any) {
			if (this.video.seeking) {
				return;
			}

			const info: any = event.detail;
			const txt = info.value || info.eventElement.getAttribute('messageData');

			this.delegate.metadataCuepoint({
				id: info.schemeIdUri,
				info: txt,
				text: txt,
				data: info.eventElement.getAttribute('messageData'),
				source: info,
				startTime: info.startTime,
				endTime: info.endTime,
			});
		}
	}

	return new ShakaAdapter(context, options);
}
