import type { PlaybackAdapterDelegateInterface, PlaybackAdapterInterface, PluginConfigInterface, PluginInterface, PluginServicesInterface } from '@cbsinteractive/avia-js';
import type { ShakaPluginOptionsInterface } from './iface/ShakaPluginOptionsInterface';
import { createShakaAdapter, isShakaSupported, SHAKA } from './ShakaAdapter';

export function createShakaPlugin(services: PluginServicesInterface, options: ShakaPluginOptionsInterface): PluginInterface {
	const { avia, logger, player } = services;

	if (typeof TextDecoder === 'undefined') {
		logger.warn('Polyfill warning: TextDecoder is not defined');
	}

	player.registerAdapter<PlaybackAdapterInterface, PlaybackAdapterDelegateInterface>({
		id: SHAKA,
		role: [avia.AdapterRole.PLAYBACK],
		options,
		factory: createShakaAdapter,
		isSupported: isShakaSupported,
	});

	return {
		getId() {
			return SHAKA;
		},

		destroy() { },
	};
}

export function shaka(options: Partial<ShakaPluginOptionsInterface> = {}): PluginConfigInterface {
	return {
		id: SHAKA,
		factory: createShakaPlugin,
		options,
	};
}
