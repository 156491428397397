// change
import { createDashPlugin, dash, DASH } from './DashPlugin';

export { createShakaAdapter, createShakaPlugin, shaka, SHAKA } from '@cbsinteractive/avia-js-shaka';
export type { ShakaAdapterOptionsInterface, ShakaDependenciesInterface } from '@cbsinteractive/avia-js-shaka';
export * from './DashPlugin';
export type { DashAdapterId, DashAdapterOptions, DashPluginOptionsInterface } from './DashPluginOptionsInterface';

// UMD Aliases
export const ID = DASH;
export const createPlugin = createDashPlugin;
export const plugin = dash;
