import type { PluginConfigInterface, PluginInterface, PluginServicesInterface } from '@cbsinteractive/avia-js';
import { createShakaPlugin, SHAKA } from '@cbsinteractive/avia-js-shaka';
import { DashAdapterId, DashPluginOptionsInterface } from './DashPluginOptionsInterface';

export const DASH = 'dash';

export function createDashPlugin(services: PluginServicesInterface, options: DashPluginOptionsInterface = {}): PluginInterface {
	const createOptions = (id: DashAdapterId) => {
		const opts = options.adapter?.[id];
		const dependencies = options.dependencies || {};
		return Object.assign({ debug: options.debug }, opts, { dependencies });
	};

	const plugins = [
		createShakaPlugin(services, createOptions(SHAKA)),
	];

	return {
		getId() {
			return DASH;
		},

		destroy() {
			plugins.map(plugin => plugin.destroy?.());
		},
	};
}

export function dash(options: DashPluginOptionsInterface = {}): PluginConfigInterface {
	return {
		id: DASH,
		factory: createDashPlugin,
		options,
	};
}
